import React from 'react'
import {useNavigate} from "react-router-dom"
import {Constants} from "../scripts/settings";

const ChatBody = ({messages, typingStatus, lastMessageRef}) => {
  const navigate = useNavigate()

  const handleLeaveChat = () => {
    localStorage.removeItem("userName")
    navigate("/")
    window.location.reload()
  }
  
  return (
    <>
      <header className='chat__mainHeader'>
          <p>{localStorage.getItem("toUserName")}</p>
        {/* // <button className='leaveChat__btn' onClick={handleLeaveChat}>LEAVE CHAT</button> */}
        </header>


        <div className='message__container'>
          {messages.map(message => (

            message.mobile === localStorage.getItem("myUserName") ? (
              <div className="message__chats" key={message.id}>
            <p className='sender__name'>You</p>
            <div className='message__sender'>
              {
                message.type == "text" ? (
                    <p>{message.message}</p>
                ):(
                    <div>
                      <img height="80px" width="80px" src={`${Constants.fileUrl}${message.message}`}></img>
                      <a target="_blank" style={{marginRight:"10px", fontWeight:"bold"}} href={`${Constants.fileUrl}${message.message}`}>دانلود</a>
                      </div>

                )
              }

              <p style={{direction:"ltr"}}>{message.createdAt}</p>
            </div>
          </div>
            ): (
              <div className="message__chats" key={message.id}>
            <div className='message__recipient'>
              {
                message.type == "text" ? (
                    <p>{message.message}</p>
                ):(
                    <div>
                      <img height="80px" width="80px" src={`${Constants.fileUrl}${message.message}`}></img>
                      <a target="_blank" style={{marginRight:"10px", fontWeight:"bold"}} href={`${Constants.fileUrl}${message.message}`}>دانلود</a>
                    </div>                )
              }
                <p style={{direction:"ltr"}}>{message.createdAt}</p>

            </div>
          </div>
            )
            ))}

          <div className='message__status'>
            <p>{typingStatus}</p>
          </div>
          <div ref={lastMessageRef} />   
        </div>
    </>
  )
}

export default ChatBody