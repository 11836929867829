import React, {useState, useEffect} from 'react'
import API from '../scripts/api';
const search = window.location.search;
const params = new URLSearchParams(search);
const fromUserMobile = params.get('mobile');
const ChatBar = ({socket, getMessages}) => {
    // const [users, setUsers] = useState([])
    const [users1, setUsers1] = useState([])
    const [user, setUser] = useState("")
    useEffect(()=> {

        getUsersChat()
        socket.on("getUsers", data => {
            console.log("newUserResponse : ", data)
            setUsers1(data)
        })
    }, [socket])
    function getUsersChat(){
        API.userChat.list({mobile:fromUserMobile})
            .then((result) => {
                setUsers1(result.data)
            });
    }
    const handleAddUser = async (e) => {
        e.preventDefault()
        console.log("user is:", user)

        let res = await socket.emit("addUser",
            {
                mobile: user,
                id: `${socket.id}${Math.random()}`,
                socketID: socket.id
            })
        console.log("res is: ", res)
        setUser("")
        getUsersChat()
    }
    const selectUser = async (mobile, name, family) => {
        console.log("e is:", mobile)
        localStorage.setItem("toUserMobile", mobile)
        localStorage.setItem("toUserName", name+" "+family)
        getMessages()
    }
  return (
    <div className='chat__sidebar'>
        {/* <h2>Open Chat</h2> */}
        <div className=''>
            <form className='form' onSubmit={handleAddUser}>
                <input
                    type="text"
                    placeholder='enter mobile'
                    className='message'
                    value={user}
                    onChange={e => setUser(e.target.value)}
                />
            </form>
        </div>
        <div>
            {/*  <h4  className='chat__header'>ACTIVE USERS</h4> */}
            <div className='chat__users'>
                {users1.map(user =>
                    <div>
                {(user.mobile == localStorage.getItem("toUserMobile")) ? (
                    <div className='inline__users active'  onClick={() => selectUser(user.mobile, user.name,user.family)}>
                        <p key={user.id}>{user.name + " " +user.family}</p>
                        { (user.unreadMessagesCount > 0) &&
                        <span className="unread__messages__number"> {user.unreadMessagesCount} </span>
                        }
                    </div>
                    ):(
                        <div className='inline__users'  onClick={() => selectUser(user.mobile, user.name,user.family)}>
                            <p key={user.id}>{user.name + " " +user.family}</p>
                            { (user.unreadMessagesCount > 0) &&
                            <span className="unread__messages__number"> {user.unreadMessagesCount} </span>
                            }
                        </div>
                        )}
                        </div>

                )}
            </div>
        </div>
  </div>
  )
}

export default ChatBar