// import {message} from 'antd'

const Actions = {
    OPEN_MENU: 'OPEN_MENU',
    SET_ROUTE: 'SET_ROUTE',
    UPDATE_USER: 'UPDATE_USER',
    SET_RULES: 'SET_RULES',
    SET_TEACHER_LESSON_CHAPTER_DATA: 'SET_TEACHER_LESSON_CHAPTER_DATA',
    SET_CONSOLTANT: 'SET_CONSOLTANT'
};

const Constants = {
    // baseUrl: 'http://127.0.0.1:9001/api/v1/',
    // fileUrl: 'http://127.0.0.1:9001/',
    // socketUrl: '127.0.0.1:9001',
    baseUrl: 'http://167.235.30.26:9001/api/v1/',
    fileUrl: 'http://167.235.30.26:9001/',
    socketUrl: '167.235.30.26:9001',
    dateFormat: 'YYYY-MM-DD',
    datetimeFormat: 'YYYY-MM-DD HH:mm',
    defaultLat: 36.323054,
    defaultLng: 59.554985,
    defaultZoom: 16,
    expireToken: 7,
    jDateFormat: 'jYYYY-jMM-jDD',
    jDateTimeFormat: 'jYYYY-jMM-jDD HH:mm',
    pageSize: 25,
    mapZoom: 15,
    maxImageSize: 5242880,
    maxAge: 20,
    minAge: 0,
};

const Patterns = {
    username: /^[A-Za-z0-9]([A-Za-z0-9_]{1,48})[A-Za-z0-9_]$/,
    urlname: /^[A-Za-z]([A-Za-z0-9-]{1,48})[A-Za-z0-9-]$/,
    mobile: /^(09)[0-9]{9}$/,
    email: /^\w+([-.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    name: /^[A-Za-z\u0600-\u06FF\u200c][A-Za-z\u0600-\u06FF\u200c ]{1,48}[A-Za-z\u0600-\u06FF\u200c]$/,
    rolename: /^[A-Za-z0-9\u0600-\u06FF\u200c][0-9A-Za-z\u0600-\u06FF\u200c ]{1,48}[A-Za-z0-9\u0600-\u06FF\u200c]$/,
    latlong: /^[0-9]{2,3}(\.)[0-9]{6}$/,
    tag: /^[a-zA-Z0-9_\u0600-\u06FF\u200c]{2,20}$/
};

const Permissions = {

};

export const HandleErorr = (resFromCatch) => {
    let data = resFromCatch['response']['data'];
    let status = resFromCatch['response']['status'];
    if(status === 422) {
        // return Object.values(data.errors).forEach(item => message.error(`${item}`, 5000));
    }
};

const Status = {};

const Types = {};

export { Actions, Constants, Patterns, Permissions, Status, Types };
