import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from "./components/Home"
import ChatPage from "./components/ChatPage";
import socketIO from "socket.io-client"
import { useLocation } from 'react-router';
import queryString from 'query-string'
import {Constants, Permissions} from "./scripts/settings";
const search = window.location.search;
const params = new URLSearchParams(search);
const fromUser = params.get('mobile');
const socket = socketIO.connect(`${Constants.socketUrl}?mobile=${fromUser}`)
localStorage.setItem("myUserName", fromUser)
localStorage.setItem("toUserMobile", "")
localStorage.setItem("toUserName", "")

function App() {
  return (

    <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home socket={socket}/>}></Route>
            <Route path="/chat" element={<ChatPage socket={socket}/>}></Route>
          </Routes>
    </div>
    </BrowserRouter>
    
  );
}

export default App;
