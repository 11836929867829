import React, {useState} from 'react'
import Modal from './../Modal/Modal.js';
const ChatFooter = ({socket}) => {
    const [message, setMessage] = useState("")
    const [file, setFile] = useState({});
    const [showModal, setShowModal] = useState(false);
    const handleTyping = () => socket.emit("typing",`${localStorage.getItem("userName")} is typing`)

    const showModalFn = () => {

        setShowModal(true)
    };

    const hideModalFn = () => {
        setShowModal(false)
    };

    const handleSendMessage = async (e) => {
        console.log("message is:", message)
        e.preventDefault()
        if(message.trim() && localStorage.getItem("toUserMobile")) {
        let res = await socket.emit("sendMessage",
            {
            message: message,
            mobile: localStorage.getItem("toUserMobile"),
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id
            }
        )
            console.log("res is: ", res)
        }
        setMessage("")
    }
    const handleFileChange = (e) => {
        console.log("file>>>", e.target.files[0])
        setFile(e.target.files[0]);
    };
    const handleFileUpload = () => {
        const file_array = file.name.split('.');

        console.log(file_array[file_array.length-1])
        socket.emit('fileUpload', {
            file_type: file_array[file_array.length-1],
            file: file,
            mobile: localStorage.getItem("toUserMobile"),
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id
        });
        setShowModal(false)
    };

  return (
    <div className='chat__footer row'>
        <div className="row">
            <form className='form ' onSubmit={handleSendMessage}>
                    <input
                        type="text"
                        placeholder='Write message'
                        className='message'
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        onKeyDown={handleTyping}
                    />
                    <button className="sendBtn">SEND</button>

            </form>
            <button style={{float:"left"}} className="sendBtn" onClick={showModalFn}>upload file</button>
        </div>


        <Modal show={showModal} handleClose={hideModalFn}>
            <div style={{marginTop:"15px"}}>
                <input type="file" onChange={handleFileChange} />
                <button style={{background:"green", color:"white"}} onClick={() => handleFileUpload()}>Upload</button>
            </div>
        </Modal>
     </div>


  )
}

export default ChatFooter