import React, { useEffect, useState, useRef} from 'react'
import ChatBar from './ChatBar'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import API from '../scripts/api';
const search = window.location.search;
const params = new URLSearchParams(search);
const fromUserMobile = params.get('mobile');
const ChatPage = ({socket}) => { 
  const [messages, setMessages] = useState([])
  const [typingStatus, setTypingStatus] = useState("")
  const lastMessageRef = useRef(null);


  useEffect(()=> {
    socket.on("getMessages", data => {
      console.log("getMessages: ", data)
      if(fromUserMobile == data.mobile){
        setMessages([...messages, data])
      }
      else if((data.mobile == localStorage.getItem("toUserMobile"))){
        setMessages([...messages, data])
      }

    })
  }, [socket, messages])
  function getMessages(){
    API.message.list({from_mobile:fromUserMobile, to_mobile:localStorage.getItem("toUserMobile")})
        .then((result) => {
          setMessages(result.data.messages)
        });
  }
  useEffect(()=> {
    socket.on("typingResponse", data => setTypingStatus(data))
  }, [socket])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  return (
    <div className="chat">
      <ChatBar getMessages={getMessages} socket={socket}/>
      <div className='chat__main'>
        <ChatBody messages={messages} typingStatus={typingStatus} lastMessageRef={lastMessageRef}/>
        <ChatFooter socket={socket} />
      </div>
    </div>
  )
}

export default ChatPage