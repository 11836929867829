import axios from 'axios';
import { Cookies } from "react-cookie";
// import { message } from 'antd';
import { Constants } from './settings';

const _cookies = new Cookies();

axios.defaults.baseURL = Constants.baseUrl;
axios.defaults.headers.common['Authorization'] = `Bearer ${_cookies.get('AccessToken') || ''}`;

const handleError = (resFromCatch) => {
    let data = resFromCatch['response']['data'];
    let status = resFromCatch['response']['status'];
    if(status === 422) {
        // return Object.values(data.errors).forEach(item => message.error(`${item}`));

    }
    if(status === 401) {
        _cookies.remove('AccessToken', { path: '/', domain: window.location.hostname });
        window.location = '/account/login';
    }
    if(status === 403) {
        window.location = '/403'
    }
    if(status === 404) {
        window.location = '/404'
    }
    if(status === 500){
        // message.error("خطای سمت سرور.")
    }
};

// panel/

const upload = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(url, params, { headers: { 'content-type': 'multipart/form-data' } })
            .then((response) => {
                if (response && response.data) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        handleError(response.data);
                        reject(response.data);
                    }
                }
            })
            .catch((error) => {
                handleError(error && error.response && error.response.data);
                reject(error && error.response && error.response.data);
            });
    });
};

const get = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {headers: {'socketToken' : localStorage.getItem("socketApiToken")}})
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                handleError(error);
                reject(error && error.response && error.response.data);
            });
    });
};

const post = (url, params = {}, skip) => {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {headers: {'socketToken' : localStorage.getItem("socketApiToken")}})
            .then((response) => {
                if (response && response.data) {
                    if (skip || response.status === 200) {
                        resolve(response.data);
                    } else {
                        handleError(response.data);
                        reject(JSON.parse(response.data));
                    }
                }
            })
            .catch((error) => {
                handleError(error);
                reject(error && error.response && error.response.data);
            });
    });
};

const del = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.delete(url, params, {headers: {'socketToken' : localStorage.getItem("socketApiToken")}})
            .then((response) => {
                if (response && response.data) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                }
            })
            .catch((error) => {
                handleError(error);
                reject(error && error.response && error.response.data);
            });
    });
};

export default {
    userChat: {
        list: (params = {}) => {
            return get(`userChat/list?mobile=${params.mobile}`);
        },
    },
    message: {
        list: (params={}) => {
            return get(`message/getMessages?from_mobile=${params.from_mobile}&to_mobile=${params.to_mobile}`);
        },
    },
};
